<template>
  <div>
    <section class="section-navbar sticky-top">
      <Navbar />
    </section>
    <div class="container-md p-3 pt-0 mt-0">
      <div class="row">
        <div class="col-12">
          <div class="p-5 bg-color-wapidu text-white">
            <div class="d-flex justify-content-center">
              <img
                src="@/assets/img/logo_white.png"
                alt="Pagina de Gracias"
                class="img-fluid img-logo"
              />
            </div>
            <hr />
            <div>
              <h5 class="heading-primary titulo-1 text-center">Pagina de Gracias</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header bg-color-wapidu-opacities-01">
              <h3>Datos de la compra</h3>
            </div>
            <div class="card-body m-4">
              <div class="table-responsive">
                <table class="table align-middle">
                  <thead>
                    <tr>
                      <h5 class="color-wapidu">Gracias. Tu pedido ha sido recibido.</h5>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="align-bottom">
                      <th>NÚMERO DEL PEDIDO:</th>
                      <th>FECHA:</th>
                      <th>TOTAL:</th>
                      <th>MÉTODO DE PAGO:</th>
                    </tr>
                    <tr>
                      <td>26945</td>
                      <td>21 septiembre, 2022</td>
                      <td>$ 473.00</td>
                      <td>Transferencia bancaria directa</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Realiza tu pago directamente en nuestra cuenta bancaria. Por favor, usa
                  el número del pedido como referencia de pago. Tu pedido no se procesará
                  hasta que se haya recibido el importe en nuestra cuenta.
                </p>
                <hr />
                <h3>Seleciona tu horario de recoleccion</h3>
                <p>Horario de recoleccion</p>
                <p>Recuerda que tienes que dar un rango minimo de 3 horas</p>

                <hr />
                <h3>Ingresa tu direccion de entrega y recolección</h3>
                <hr />
                <h3 class="titulo-2 text-center">Nuestros detalles bancarios</h3>
                <ul>
                  <li class="me-2">
                    <p>
                      Pago por paypal<img
                        src="https://www.paypalobjects.com/webstatic/icon/pp258.png"
                        alt=""
                        class="img-paypal ms-2"
                      />
                    </p>
                  </li>
                  <li class="me-2">
                    <p>
                      Pago por paypal<img
                        src="https://play-lh.googleusercontent.com/2PS6w7uBztfuMys5fgodNkTwTOE6bLVB2cJYbu5GHlARAK36FzO5bUfMDP9cEJk__cE"
                        alt=""
                        class="img-paypal ms-2"
                      />
                    </p>
                  </li>
                  <li class="me-2">
                    <p>
                      Pago por Mercado Pago<img
                        src="https://play-lh.googleusercontent.com/4hN-UTy-2_Ma1Ouye5FpN2Issj73Oms62hokLp5OZR6zdt2yzkEpGSpK0v47RK8Oc8Q"
                        alt=""
                        class="img-paypal ms-2"
                      />
                    </p>
                  </li>
                </ul>
              </div>
              <div class="card">
                <div class="card-header bg-color-wapidu-opacities-01">
                  <h3>Detalles del pedido</h3>
                </div>
                <div class="card-body m-4">
                  <!-- <div class="table-responsive">
                    <table class="table align-middle">
                      <tbody>
                        <tr class="align-bottom">
                          <th>Productos</th>
                          <th>Total</th>
                        </tr>
                        <tr>
                          <td>Lavandería × 1</td>
                          <td>$ 25.00</td>
                        </tr>
                        <tr>
                          <td>Tintoreria</td>
                          <td>$ 25.00</td>
                        </tr>
                        <tr>
                          <td>Suscripción Lavandería</td>
                          <td>$ 350.00</td>
                        </tr>
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ 400.00</td>
                        </tr>
                        <tr>
                          <td>Envio:</td>
                          <td>$ 0.00</td>
                        </tr>
                        <tr>
                          <td>Total:</td>
                          <td>$ 400.00</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div> -->
                  <div class="col-12">
                    <CardCarrito></CardCarrito>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/navbar/index.vue';
import CardCarrito from '@/components/tienda/carrito/index.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'PagThankYou',
  data() {
    return {
      totalProduct: 'value',
    };
  },
  components: {
    Navbar,
    CardCarrito,
    // Modal,
    // Payment,
  },
  comments: {},
  computed: {
    ...mapGetters('signup', ['productOnCart', 'cartTotal', 'cartTotalUrgent']),
    ...mapState('signup', ['customer', 'address', 'step']),
  },
  methods: {
    async saveOrder() {
      this.$store.commit('showLoader');
      try {
        let paid = false;
        let pickup = false;
        let paidPickup = false;

        if (this.$store.state.signup.individualSelection) {
          paid = true;
        } else {
          pickup = true;
          paidPickup = true;
        }

        const { number } = await this.api.create({
          customer_id: this.$store.state.signup.customer.uuid,
          date: this.$store.state.signup.date,
          discount: this.$store.state.signup.discount,
          items: this.$store.state.signup.products,
          office_uuid: this.$store.state.signup.office.uuid,
          time_end: this.$store.state.signup.time_end,
          time_start: this.$store.state.signup.time_start,
          paid_pickup: paidPickup,
          paid,
          pickup,
        });

        this.$store.dispatch('notification/notifySuccess');
        this.orderNumber = number;
        this.showConfirmationModal = true;
      } catch (error) {
        this.$store.dispatch(
          'notification/notifyError',
          'No se pudieron guardar los datos.',
        );
      }
      this.$store.commit('hideLoader');
    },

    getTotalItems() {
      let total = this.$store.state.signup.products.reduce(
        // eslint-disable-next-line no-return-assign, no-param-reassign
        (sum, item) => (sum += item.product.unit_price_normal * item.quantity),
        0,
      );

      if (!this.$store.state.signup.individualSelection) {
        total = 35;
      }

      return total;
    },

    showPayment() {
      this.total = this.getTotalItems();
      this.showPaymentModal = true;
    },

    redirectToLogin() {
      this.$store.commit('signup/reset');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style>
.img-paypal {
  width: 50px;
}
.bg-color-wapidu {
  background: rgb(27, 29, 77);
  background: linear-gradient(16deg, rgba(27, 29, 77, 1) 0%, rgba(63, 81, 181, 1) 59%);
}
.bg-color-wapidu-opacities-01 {
  background: rgba(27, 29, 77, 0.174);
  background: linear-gradient(
    16deg,
    rgba(27, 29, 77, 0.1) 0%,
    rgba(63, 81, 181, 0.1) 59%
  );
}
.titulo-1 {
  font-size: 2.5rem !important;
  font-weight: 700;
  color: #fff;
}
.titulo-2 {
  font-size: 1.5rem !important;
  font-weight: 700;
}
.color-wapidu {
  color: #1b1d4d;
}
.img-logo {
  width: 25%;
  height: auto;
}
@media (max-width: 768px) {
  .titulo-1 {
    font-size: 2.5rem !important;
  }
  .img-logo {
    width: 50%;
    height: auto;
  }
}
</style>
